











































import Vue from 'vue'
import ListCategoryEmoji from './emoticons.json'

interface ListCategoryEmojiType {
  name: string
  values: Array<(string | string[])>
}

interface DataType {
  disabledScroll: boolean
  selectedEmot: string[]
  ListCategoryEmoji: ListCategoryEmojiType[]
}

export default Vue.extend({
  name: "Emoticons",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    }
  },
  data(): DataType {
    return {
      disabledScroll: false,
      selectedEmot: [] as string[],
      ListCategoryEmoji
    }
  },
  methods: {
    onSelectEmoji(emoji: string | string[], event: Event, isSetPosition: boolean): void {
      const wrapperOptionsEmoji = document.querySelector('.wrapper-options-emoji')
      const container = wrapperOptionsEmoji?.getBoundingClientRect() as DOMRect
      const emojiTarget = (<HTMLElement>event.target).getBoundingClientRect()
      let offsetX = container.right - emojiTarget.right
      let offsetY = container.bottom - emojiTarget.bottom
      if (isSetPosition) this.setPositionVariantEmoji(event, offsetX, offsetY)

      if (Array.isArray(emoji)) {
        this.disabledScroll = false
        if (this.selectedEmot === emoji) {
          this.disabledScroll = false
          this.selectedEmot = []
        } else {
          this.disabledScroll = true
          this.selectedEmot = emoji
        }
      } else {
        this.$emit('select-emoji', emoji)
      }
    },
    setPositionVariantEmoji(event: Event, offsetX: number, offsetY: number): void {
      const currentTarget = event.currentTarget
      const listVariant = <HTMLDivElement>(<HTMLElement>currentTarget).querySelector('.list-variant-emoji')

      // Set dynamic position variant emoji
      if (offsetY > 50) {
        listVariant.style.bottom = "-40px";
      } else {
        listVariant.style.top = "-40px";
      }

      if (offsetX < 120) {
        listVariant.style.right = "0";
      } else if (offsetX > 120 && offsetX < 160) {
        listVariant.style.right = "-80px";
      } else {
        listVariant.style.left = "0";
      }
    }
  },
});
