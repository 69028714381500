






























import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'

@Component({
  inheritAttrs: false,
  components: { TextInput },
})
export default class PNTextInput extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop({ default: false }) private required!: boolean
  @Prop() private fieldDescription!: string
  @Prop() private fieldSuffix!: string
  @Prop() private errorMessage!: string
}
