





















import Vue from 'vue'
import IconImage from '@/app/ui/components/TextEditor/icons/icon_image.vue'
import controller from '@/app/ui/controllers/FAQController'

interface DataType {
  showTooltip: boolean
}

export default Vue.extend({
  name: 'button-upload-image',
  components: {
    IconImage,
  },
  props: {
    isActive: { type: Boolean, default: false },
    customClass: { type: String, default: '' },
  },
  data(): DataType {
    return {
      showTooltip: false,
    }
  },
  methods: {
    onShowTooltip(bool: boolean): void {
      this.showTooltip = bool
    },
    async onInputImage(event: { target: { files: File[]; value: string } }): Promise<void> {
      const { files } = event.target
      if (files) {
        const url = await controller.postImageCategory(files[0])
        this.$emit('input', url)
        event.target.value = ''
      }
    },
  },
})
