





































































































import Vue from 'vue'
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import MenuButton from "./components/MenuButton/index.vue";
import Emoticons from '@/app/ui/components/TextEditor/components/Emoticons/index.vue'
import UploadImage from '@/app/ui/components/TextEditor/components/UploadImage/index.vue'
import CharacterCount from '@tiptap/extension-character-count'
import Image from './extensions/Image';

interface DataType {
  countWatch: number
  tabActive: string
  showEmoji: boolean
  editor: Editor | null
  menu: boolean
}

export default Vue.extend({
  components: {
    EditorContent,
    MenuButton,
    Emoticons,
    UploadImage,
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    fieldLabel: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    maxCount: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
  },
  data(): DataType {
    return {
      countWatch: 0,
      tabActive: '',
      showEmoji: false,
      editor: null,
      menu: false,
    }
  },
  created(): void {
    this.editor = new Editor({
      content: this.$props.value,
      extensions: [
        StarterKit,
        Link,
        Image,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Link.configure({
          openOnClick: false,
        }),
        Placeholder.configure({
          placeholder: () => {
            return this.placeholder
          },
        }),
        CharacterCount,
      ],
    });
  },
  methods: {
    onClickTab(key: string): void {
      if (this.tabActive === key) {
        this.tabActive = ''
      } else {
        this.tabActive = key
      }
    },
    openMenu(): void {
      this.menu = true;
    },
    addImage(url: string): void {
      if (url) {
        (<Editor>this.editor).chain().focus().setImage({ src: url }).run()
      }
    },
    onClickEmoji(): void {
      this.showEmoji = !this.showEmoji
      if (this.tabActive === 'emoji') {
        this.tabActive = ''
      } else {
        this.tabActive = 'emoji'
      }
    },
    onSelectEmoji(value: string): void {
      this.editor?.chain().focus().insertContent(value).run()
    },
    onSetLink(): void {
      const previousUrl = this.editor?.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) return

      // empty
      if (url === '' && this.editor) {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
        return
      }

      // update link
      this.editor?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  },
  computed: {
    valueEditor(): string {
      if (!this.editor) {
        return ''
      } else {
        return this.editor?.getHTML()
      }
    },
  },
  watch: {
    value(val: string) {
      if (this.countWatch < 2) {
        this.editor?.chain().focus().setContent(val).run()
        this.countWatch = this.countWatch + 1 // for stop watch value
      }
    },
    valueEditor(value: string): void {
      this.$emit('input', value)
    }
  },

  beforeDestroy(): void {
    (<Editor><unknown>this.editor).destroy();
  },
});
