































import Vue from 'vue'
import IconBold from '@/app/ui/components/TextEditor/icons/icon_bold.vue'
import IconItalic from '@/app/ui/components/TextEditor/icons/icon_italic.vue'
import IconBullets from '@/app/ui/components/TextEditor/icons/icon_bullets.vue'
import IconOrderList from '@/app/ui/components/TextEditor/icons/icon_order-list.vue'
import IconImage from '@/app/ui/components/TextEditor/icons/icon_image.vue'
import IconLinks from '@/app/ui/components/TextEditor/icons/icon_links.vue'
import IconSmile from '@/app/ui/components/TextEditor/icons/icon_smile.vue'
import IconLeftAlignment from '@/app/ui/components/TextEditor/icons/icon_left_alignment.vue'
import IconCenterAlignment from '@/app/ui/components/TextEditor/icons/icon_center_alignment.vue'
import IconRightAlignment from '@/app/ui/components/TextEditor/icons/icon_right_alignment.vue'
import IconJustifyAlignment from '@/app/ui/components/TextEditor/icons/icon_justify_alignment.vue'

interface DataType {
  showTooltip: boolean
}

export default Vue.extend({
  name: "menu-button",
  components: {
    IconBold,
    IconItalic,
    IconBullets,
    IconOrderList,
    IconImage,
    IconLinks,
    IconSmile,
    IconLeftAlignment,
    IconCenterAlignment,
    IconRightAlignment,
    IconJustifyAlignment
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: 'Tooltip'
    }
  },
  data(): DataType {
    return {
      showTooltip: false,
    }
  },
  methods: {
    onShowTooltip(bool: boolean): void {
      this.showTooltip = bool
    }
  }
});
