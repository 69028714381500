


















































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  and,
  or,
  url as urlValidation,
  requiredUnless,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import dayjs from 'dayjs'
import controller from '@/app/ui/controllers/PNController'
import routeController, {
  Data as RouteData,
} from '@/app/ui/controllers/RouteController'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import TextEditor from '@/app/ui/components/TextEditor/index.vue'
import PNTextInput from '../components/PNTextInput/index.vue'
import PNDropdown from '../components/PNDropdown/index.vue'
import PNDatePicker from '../components/PNDatePicker/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import { semanticVersioning } from '@/app/infrastructures/misc/ValidationRules'
import * as TypeData from '@/app/ui/components/OptionBox/interfaces'
import Note from '@/app/ui/components/Note/Note.vue'
import * as dataConstant from '@/app/infrastructures/misc/Constants/pushNotification'

@Component({
  mixins: [validationMixin],
  components: {
    Note,
    Button,
    Modal,
    LoadingOverlay,
    PNTextInput,
    PNDropdown,
    PNDatePicker,
    OptionBox,
    ImageUpload,
    ConfirmationIcon,
    TextEditor,
  },
})
export default class CreatePushNotificationPage extends Vue {
  controller = controller
  dataConstant = dataConstant
  routeController = routeController
  confirmationModal = false
  successModal = false
  todayDate = dayjs().format('YYYY-MM-DD')
  isImageValid = false
  isValidate = false
  form = {
    title: '',
    wording: '',
    content: '',
    targetUser: <Record<string, string>>(<unknown>[]),
    fileUsers: new File([], ''),
    cities: [] as Record<string, number | string>[],
    membership: [] as string[],
    platformOS: dataConstant.platformOSOptions[0],
    platformValidation: dataConstant.platformValidationOptions[0] as Record<
      string,
      string
    >,
    platformVersion: '',
    publishDate: '',
    publishTime: '',
    pnImage: <File[] | Array<undefined>>[],
    targetUrl: '',
    ctaName: '',
    category: <Record<string, string>>(<unknown>[]),
    imageOption: dataConstant.imageOptions[0],
  }
  hasChanged = {
    title: false,
    wording: false,
    content: false,
    targetUser: false,
    fileUsers: false,
    cities: false,
    membership: false,
    platformVersion: false,
    publishDate: false,
    publishTime: false,
    pnImage: false,
    targetUrl: false,
    ctaName: false,
  }

  created(): void {
    routeController.getCityList()
    controller.getListOptions()
  }

  @Validations()
  validations(): dataConstant.ValidationsInterface {
    let validate = {
      form: {
        category: { required },
        targetUser: { required },
        fileUsers: { name: {} },
        membership: {},
        cities: {},
        platformVersion: {
          requiredIf: and(
            requiredIf(
              () =>
                this.form.targetUser.value ===
                this.controller.targetOptions[4].value
            ),
            semanticVersioning
          ),
        },
        title: { required, maxLength: maxLength(70) },
        wording: { required, maxLength: maxLength(100) },
        content: {
          required,
          maxLength: (value: string) => Utils.stripTags(value).length <= 1000,
        },
        publishDate: { required },
        publishTime: { required },
        targetUrl: {
          url: and(
            requiredUnless(() => !this.form.ctaName),
            or(urlValidation, (value: string) =>
              value.startsWith('lionparcel://app')
            )
          ),
        },
      },
    }

    if (this.form.targetUser.value === controller.targetOptions[0].value) {
      validate = {
        form: {
          ...validate.form,
          fileUsers: {
            ...validate.form.fileUsers,
            name: { required },
          },
        },
      }
    }

    if (this.form.targetUser.value === controller.targetOptions[2].value) {
      validate = {
        form: {
          ...validate.form,
          cities: {
            minLength: (value: Array<Record<string, number | string>>) =>
              value.length > 0,
          },
        },
      }
    }

    if (this.form.targetUser.value === controller.targetOptions[3].value) {
      validate = {
        form: {
          ...validate.form,
          membership: {
            minLength: (value: Array<Record<string, number | string>>) =>
              value.length > 0,
          },
        },
      }
    }

    return validate
  }

  @Watch('controller.errorCreateNotification')
  onErrorCreateNotification(val: string | null): void {
    if (typeof val === 'string') {
      if (val === '') {
        this.successModal = true
      } else {
        const onErrorProgramMembership = 'active'
        if (
          val.toLowerCase().includes(onErrorProgramMembership) &&
          this.isTargetMembership
        ) {
          this.form.membership = []
          setTimeout(() => {
            this.$notify({
              title: 'Create Push Notification Failed',
              text: val,
              type: 'warning',
              duration: 3000,
            })
          }, 500)
          controller.getListProgramMembership()
          window.scroll(0, 0)
        } else {
          this.$notify({
            title: 'Create Push Notification Failed',
            text: val,
            type: 'error',
            duration: 5000,
          })
        }
      }
    }
  }

  get fileAttachmentName(): string | null {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.form.fileUsers ? this.form.fileUsers.name! : null
  }

  get routeOptions(): RouteData[] {
    return routeController.cityData
  }

  private stripTags(input: string): string {
    return Utils.stripTags(input)
  }

  private onInputContent(value: string): void {
    this.form.content = value
  }

  private onFileChange($event: File): void {
    this.form.fileUsers = $event
  }

  private onImageUpload($event: File): void {
    this.form.pnImage.splice(0, 1, $event)
  }

  private onRemoveImage(index: number): void {
    this.form.pnImage.splice(index, 1)
  }

  private setMembershipTier(value: string): void {
    this.hasChanged.membership = true
    if (this.form.membership.includes(value)) {
      this.form.membership = this.form.membership.filter(item => item !== value)
    } else {
      this.form.membership.push(value)
    }
  }

  private setAllChanged(): void {
    this.hasChanged = {
      title: true,
      wording: true,
      content: true,
      targetUser: true,
      fileUsers: true,
      cities: true,
      membership: true,
      platformVersion: true,
      publishDate: true,
      publishTime: true,
      pnImage: this.isImageValid && this.hasChanged.pnImage,
      targetUrl: true,
      ctaName: true,
    }
  }

  private onCreatePN(): void {
    this.isValidate = true
    this.setAllChanged()

    if (!this.$v.form.$invalid) {
      this.confirmationModal = false
      const payload = {
        ...this.form,
        pnImage: this.form.pnImage[0],
      }
      controller.createPN(payload)
    } else {
      this.$notify({
        title: 'Create Push Notification Invalid',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'PNListPage' })
  }

  private formatDate(date: string): string {
    return Utils.formatTimeZone(Utils.formatDate(date, 'DD/MM/YYYY HH:mm Z'))
  }

  get acceptedFormat(): string {
    let fileFormat = 'GIF'
    if (this.form.imageOption.value === 'static') {
      fileFormat = 'JPG/PNG'
    }

    return fileFormat
  }

  get acceptedImages(): string {
    let fileFormat = 'image/gif'
    if (this.form.imageOption.value === 'static') {
      fileFormat = 'image/jpg,image/jpeg,image/png'
    }

    return fileFormat
  }

  get maxFileSize(): number {
    let maxFile = 2048
    if (this.form.imageOption?.value === 'static') {
      maxFile = 1024
    }

    return maxFile
  }

  get membershipOptions(): TypeData.GroupOption<string, string>[] {
    return controller.listProgramMembership.map(val => {
      return {
        label: val.label,
        value: String(val.value),
      }
    })
  }

  get showNoteEmptyProgramMembership(): boolean {
    return controller.hasDataProgramMembership === 'empty'
  }

  get isTargetMembership(): boolean {
    return this.form.targetUser.value === controller.targetOptions[3].value
  }

  get isInvalidMembershipLevelTarget(): boolean {
    return this.isTargetMembership && this.showNoteEmptyProgramMembership
  }
}
